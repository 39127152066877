import React, { useState } from 'react';
import mailicon from '../../imgs/envelope-icon.png';
import './ContactSection.css'; // External CSS for styling

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Submitting...');
    const response = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        access_key: 'your-web3forms-access-key', // Replace with your Web3Forms key
        ...formData,
      }),
    });

    if (response.ok) {
      setStatus('Message sent successfully!');
      setFormData({ name: '', email: '', message: '' }); // Clear form
    } else {
      setStatus('Failed to send message. Please try again later.');
    }
  };

  return (
    <div className="contact-page">
    <div className="contact-container">
      <div className="contact-left">
        <img src={mailicon} alt="Mail Icon" className="mail-icon" />
      </div>
      <div className="contact-right">
        <h2>Get in Touch</h2>
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <textarea
              name="message"
              placeholder="Message"
              rows="4"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit" className="submit-button">
            Send Email →
          </button>
        </form>
        {status && <p className="form-status">{status}</p>}
      </div>
    </div>
  </div>
  );
}

export default ContactForm;