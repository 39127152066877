import React, { useEffect, useState } from 'react';
import Modal from "react-modal";
import ContactFormModal from "../../Contact/ContactFormModal.jsx";
import '../../Artists/ArtistsProfile.css';
import Oscarmimg from "../../../imgs/artists-imgs/oscarm-pro.png";
import Oscarmbg from '../../../imgs/artists-imgs/oscarm-bgbanner.jpg';
import { FaInstagram, FaFacebook, FaYoutube, FaTwitter, FaTiktok  } from 'react-icons/fa';

// Prevent screen readers from focusing on the background
Modal.setAppElement('#root');

function SocialCounter({ targetNumber }) {
  const [currentNumber, setCurrentNumber] = useState(0);

  useEffect(() => {
    const duration = 1500; // Duration of the animation in ms
    const intervalTime = 50; // Interval at which the number increments
    const increment = Math.ceil(targetNumber / (duration / intervalTime)); // Calculate increment per interval

    const interval = setInterval(() => {
      setCurrentNumber((prevNumber) => {
        if (prevNumber + increment >= targetNumber) {
          clearInterval(interval);
          return targetNumber; // Ensure it stops at the final number
        }
        return prevNumber + increment;
      });
    }, intervalTime);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [targetNumber]);

  return <span>{currentNumber.toLocaleString()}</span>; // Format number with commas
}

function OscarMPage() {
    // Modal Setup
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [currentArtist, setCurrentArtist] = useState("");
  
    const openModal = (title, artistName) => {
      setModalTitle(title); // Set button text as title
      setCurrentArtist(artistName); // Set the artist's name dynamically
      setIsModalOpen(true);
    };
    const closeModal = () => setIsModalOpen(false);


  const [isExpanded, setIsExpanded] = useState(false);


  const followerCounts = {
    twitter: 8000,     // Converted numbers into actual integers for animation
    facebook: 831000,
    instagram: 627000,
    youtube: 124000,
    tiktok:  1200000,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleBio = () => {
    setIsExpanded(!isExpanded);
  };

  const containerStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url(${Oscarmbg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  };

  return (
    <div className="artists-bg" style={containerStyle}>
      <div className="artist-detail">
        <div className="artist-left">
          <img src={Oscarmimg} alt="Jerry Garcia" className="artistpro-image" />
        </div>
        <div className="artist-right">
          <h1 className="artist-name">OSCAR MIRANDA</h1>

          {/* Social Media Follower Counter */}
          <div className="follower-section-compact">
            <div className="follower-box-compact facebook">
              <FaFacebook className="social-icon-compact" />
              <div className="follower-count-compact">
                <SocialCounter targetNumber={followerCounts.facebook} />
              </div>
              <div className="follower-text-compact">Fans</div>
            </div>

            <div className="follower-box-compact twitter">
              <FaTwitter className="social-icon-compact" />
              <div className="follower-count-compact">
                <SocialCounter targetNumber={followerCounts.twitter} />
              </div>
              <div className="follower-text-compact">Followers</div>
            </div>

            <div className="follower-box-compact instagram">
              <FaInstagram className="social-icon-compact" />
              <div className="follower-count-compact">
                <SocialCounter targetNumber={followerCounts.instagram} />
              </div>
              <div className="follower-text-compact">Followers</div>
            </div>

            <div className="follower-box-compact youtube">
              <FaYoutube className="social-icon-compact" />
              <div className="follower-count-compact">
                <SocialCounter targetNumber={followerCounts.youtube} />
              </div>
              <div className="follower-text-compact">Subscribers</div>
            </div>

            <div className="follower-box-compact tiktok">
              <FaTiktok className="social-icon-compact" />
              <div className="follower-count-compact">
                <SocialCounter targetNumber={followerCounts.tiktok} />
              </div>
              <div className="follower-text-compact">Followers</div>
            </div>
          </div>

          {/* Buttons for Booking and Contact */}
        <div className="button-section">
        <button
          className="artist-button"
          onClick={() => openModal("Booking", "OSCAR M")}
        >
          Booking
        </button>
        <button
          className="artist-button"
          onClick={() => openModal("Contact", "OSCAR M")}
        >
          Contact
        </button>
      </div>

          {/* Modal Component */}
          <ContactFormModal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle} // Button text
        artistName={currentArtist} // Artist's name
        formAction="https://api.web3forms.com/submit" // Web3Forms API
      />

          <p className={`artists-bio ${isExpanded ? "expanded" : "collapsed"}`}>
          Professionally, Oscar Miranda is a comedian, actor, writer, director, content creator and entrepreneur. 
          Personally, he’s a family man, sports junkie and all around fun individual.Oscar Miranda was born in Bakersfield, CA but raised in Van Nuys, CA. 
          Being that he was in the San Fernando Valley his whole life, he loved entertainment so much, 
          he would drive by iconic studios such as, Universal, Warner Bros., Parmount, etc. 
          just to get a small glimpse of what he wanted to do for the rest of his life. 
          He fell in love with movies, shows and enjoyed watching the behind-the-scenes process which eventually led to him creating his own version of shows and movies.
          Started creating scripted comedic content on vine in 2013 and never looked back. 
          Oscar has been able to turn creating short form videos into a full-time career and was able to grow and use his following as leverage for multiple opportunities. 
          </p>

          <button className="toggle-bio-button" onClick={toggleBio}>
            {isExpanded ? "Show Less" : "Read More"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default OscarMPage;