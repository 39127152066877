import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import ContactFormModal from "../../Contact/ContactFormModal.jsx";
import "../../Artists/ArtistsProfile.css";
import Doknowsworldimg from "../../../imgs/artists-imgs/Doknow-pro.png";
import Doknowsworldbg from '../../../imgs/artists-imgs/doknowsworld-banner.jpg';
import { FaTwitter, FaFacebook, FaInstagram, FaYoutube, FaTiktok } from 'react-icons/fa';

// Prevent screen readers from focusing on the background
Modal.setAppElement('#root');


function SocialCounter({ targetNumber }) {
  const [currentNumber, setCurrentNumber] = useState(0);

  useEffect(() => {
    const duration = 1500; // Duration of the animation in ms
    const intervalTime = 50; // Interval at which the number increments
    const increment = Math.ceil(targetNumber / (duration / intervalTime)); // Calculate increment per interval

    const interval = setInterval(() => {
      setCurrentNumber((prevNumber) => {
        if (prevNumber + increment >= targetNumber) {
          clearInterval(interval);
          return targetNumber; // Ensure it stops at the final number
        }
        return prevNumber + increment;
      });
    }, intervalTime);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [targetNumber]);

  return <span>{currentNumber.toLocaleString()}</span>; // Format number with commas
}

function DoknowsworldPage() {
  // Modal Setup
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [currentArtist, setCurrentArtist] = useState("");

  const openModal = (title, artistName) => {
    setModalTitle(title); // Set button text as title
    setCurrentArtist(artistName); // Set the artist's name dynamically
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);
  

  const followerCounts = {
    twitter: 154000,     // 154K
  facebook: 1700,      // 1.7K
  instagram: 755000,   // 755K
  youtube: 252000,     // 252K
  tiktok: 1700000,     // 1.7M
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const containerStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url(${Doknowsworldbg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  };


  return (
    <div className="artists-bg" style={containerStyle}>
      
    <div className="artist-detail">
      <div className="artist-left">
        <img src={Doknowsworldimg} alt="Concrete" className="artistpro-image" />
      </div>
      <div className="artist-right">
        <h1 className="artist-name">Doknowsworld</h1>

        {/* Social Media Follower Counter */}
        <div className="follower-section-compact">
          <div className="follower-box-compact facebook">
            <FaFacebook className="social-icon-compact" />
            <div className="follower-count-compact">
              <SocialCounter targetNumber={followerCounts.facebook} />
            </div>
            <div className="follower-text-compact">Fans</div>
          </div>

          <div className="follower-box-compact twitter">
            <FaTwitter className="social-icon-compact" />
            <div className="follower-count-compact">
              <SocialCounter targetNumber={followerCounts.twitter} />
            </div>
            <div className="follower-text-compact">Followers</div>
          </div>

          <div className="follower-box-compact instagram">
            <FaInstagram className="social-icon-compact" />
            <div className="follower-count-compact">
              <SocialCounter targetNumber={followerCounts.instagram} />
            </div>
            <div className="follower-text-compact">Followers</div>
          </div>

          <div className="follower-box-compact youtube">
            <FaYoutube className="social-icon-compact" />
            <div className="follower-count-compact">
              <SocialCounter targetNumber={followerCounts.youtube} />
            </div>
            <div className="follower-text-compact">Subscribers</div>
          </div>

          <div className="follower-box-compact tiktok">
            <FaTiktok className="social-icon-compact" />
            <div className="follower-count-compact">
              <SocialCounter targetNumber={followerCounts.tiktok} />
            </div>
            <div className="follower-text-compact">Followers</div>
          </div>
        </div>

        {/* Buttons for Booking and Contact */}
        <div className="button-section">
        <button
          className="artist-button"
          onClick={() => openModal("Booking", "DOKNOW")}
        >
          Booking
        </button>
        <button
          className="artist-button"
          onClick={() => openModal("Contact", "DOKNOW")}
        >
          Contact
        </button>
      </div>

          {/* Modal Component */}
          <ContactFormModal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle} // Button text
        artistName={currentArtist} // Artist's name
        formAction="https://api.web3forms.com/submit" // Web3Forms API
      />

        <p className="artists-bio">
        DOKNOW IS A YOUNG COMEDIAN WHO MADE HIS RISE THROUGH INSTAGRAM/TIKTOK REELS  AND TOOK OVER THE INTERNET. 
        MOST KNOWN FOR HIS FUNNY/ CRAZY THOUGHTS ON REAL LIFE SITUATIONS, RELATIONSHIPS AND MORE. 
        HES ALSO IS PART OF BROWN BAG PODCAST, FIGGMUNITY WORLD AND MORE.
        </p>
      </div>
    </div>
    </div>

);

}

export default DoknowsworldPage;
