import React, { useState } from "react";
import Modal from "react-modal";
import "./ContactFormModal.css";

const ContactFormModal = ({ isOpen, onClose, title, artistName, formAction }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: title || "", // Default to title
    message: "",
  });

  // Update the subject when the title changes
  React.useEffect(() => {
    setFormData((prevFormData) => ({ ...prevFormData, subject: title }));
  }, [title]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(formAction, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Your message has been sent!");
        setFormData({ name: "", email: "", subject: title, message: "" }); // Reset form
        onClose(); // Close modal
      } else {
        alert("Failed to send message. Please try again later.");
      }
    } catch (error) {
      alert("An error occurred. Please try again later.");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal"
      overlayClassName="overlay"
      ariaHideApp={false}
    >
      <h2>{title}</h2>
      {/* Display the artist's name prominently */}
      <p className="artist-name-display">
       Contact Form
      </p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            name="artist"
            placeholder="Artist's Name"
            value={artistName}
            readOnly
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            readOnly
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <textarea
            name="message"
            placeholder="Message"
            rows="4"
            value={formData.message}
            onChange={(e) =>
              setFormData({ ...formData, message: e.target.value })
            }
            required
          ></textarea>
        </div>
        <button type="submit">Send</button>
      </form>
      <button className="close-button" onClick={onClose}>
        X
      </button>
    </Modal>
  );
};

export default ContactFormModal;